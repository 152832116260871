<template>
  <div class="login">
    <div class="login-bg">
      <div class="logo"></div>
      <div class="title">{{ title }}</div>
      <div class="login-box">
        <input type="text" placeholder="账号" v-model="username" />
        <input type="password" placeholder="密码" v-model="password" />
        <div class="button" @click="submit">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { authorize, bindUser, isLogin } from "@/service";
export default {
  name: "login",
  components: {},
  data() {
    return {
      title: "非学历教育培训学员手册",
      url: "",
      username: "",
      password: "",
      openId: localStorage.getItem("openId"),
      uuid: localStorage.getItem("UUID"),
      sid: localStorage.getItem("SID"),
    };
  },
  created() {
    this.openIdLocal();
  },
  methods: {
    // openId
    openIdLocal() {
      if (this.$route.query.openId) this.openId = this.$route.query.openId;
      this.isLogin();
      if (this.openId) localStorage.setItem("openId", this.openId);
      // Notify({ type: "primary", message: `${this.openId}-${this.uuid}` });
    },
    // 唤醒微信授权
    authorize() {
      authorize().then(({ data }) => {
        window.location.href = data;
      });
    },
    // 判断登录是否过期
    isLogin() {
      if (
        (!this.uuid || !this.sid) &&
        (!this.openId || !localStorage.getItem("openId"))
      ) {
        // 不包含UUID、SID和openId时跳转授权绑定
        this.authorize();
      } else {
        if (!this.uuid || !this.sid) {
          console.error("注销登录");
        } else {
          // 包含UUID和SID时直接登录
          isLogin({ uuid: this.uuid, __sid: this.sid }).then((res) => {
            if (res.data) {
              localStorage.setItem("SID", res.data);
              this.$router.replace({
                path: "/home",
              });
            }
          });
        }
      }
    },

    // 绑定账号密码
    submit() {
      // let openId = "b21IalpzekRETWZIcnZJQURyWElDUVU3c3JKSQ==";
      let openId = this.openId;
      if (!openId) {
        Notify({ type: "warning", message: "微信授权未成功" });
      } else if (this.username && this.password) {
        // 拿到openId，和用户名密码绑定到一起，成功后跳转到首页
        bindUser(
          window.btoa(this.username),
          window.btoa(this.password),
          window.btoa(openId)
        ).then(({ data, message, result }) => {
          if (result === "false") {
            Notify({ type: "danger", message: message });
          } else {
            Notify({ type: "success", message: message });
            // showContacts是否显示通讯录
            // showLearn是否显示学习掠影
            localStorage.setItem("userInfo", JSON.stringify(data));
            localStorage.setItem("UUID", data.mobileUuid);
            localStorage.setItem("SID", data.sid);
            setTimeout(() => {
              this.$router.push({
                path: "/home",
              });
            }, 500);
          }
        });
      } else {
        Notify({ type: "warning", message: "请输入用户名或密码" });
      }
    },
  },
};
</script>

<style lang="less">
.login {
  width: 100vw;
  .login-bg {
    width: 100%;
    height: 100vh;
    background: url("../static/login-bg.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
  .logo {
    width: 322px;
    height: 55px;
    background: url("../static/login-logo.png") 0 0 no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    border-top: 46px solid transparent;
  }
  .title {
    width: 324px;
    height: 41px;
    font-size: 29px;
    font-family: NotoSerifSC-Bold, NotoSerifSC;
    font-weight: bold;
    color: #ffffff;
    line-height: 41px;
    margin: 10px auto 0;
  }
  .login-box {
    width: 282px;
    height: 204px;
    background: #ffffff;
    border-radius: 10px;
    margin: 76px auto 0;
    padding: 28px 17px;
    input {
      width: 260px;
      height: 48px;
      line-height: 48px;
      border-radius: 4px;
      border: 1px solid #d8d8d8;
      margin-bottom: 24px;
      padding: 0 11px;
    }
    .button {
      width: 282px;
      height: 48px;
      line-height: 48px;
      background: linear-gradient(270deg, #407cdb 0%, #06a9f4 100%);
      border-radius: 4px;
      text-align: center;
      color: #fff;
    }
  }
}
</style>
